import { ApolloProvider } from '@apollo/client'
import React from 'react'
import getApolloClient from '../apollo-client'

const ApolloProviderLoaded: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return <ApolloProvider client={getApolloClient()}>{children}</ApolloProvider>
}

export default React.memo(ApolloProviderLoaded)
